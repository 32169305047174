<template>
    <v-row no-gutters>
        <v-col class="main-col" lg="7">
            <v-container class="pa-4 pa-sm-12 pt-sm-8">
                <v-card width="600px" elevation="0" rounded class="pa-4 pa-sm-6">
                    <img class="mb-10" style="width: 150px;" width="232px;" :src="require(`@/assets/svg/voiceform_full_black.svg`)"/>
                    <div class="text-h5 text-md-h4 mb-6">
                        Login
                    </div>
                    <v-form 
                        ref="form" 
                        v-model="valid"
                        lazy-validation
                    >
                        <div class="text-subtitle-1 mb-4">Email Address</div>
                        <v-text-field 
                            hide-details="auto" 
                            label="Email Address" 
                            :rules="emailRules"
                            validate-on-blur
                            v-model="form.email"
                            @keyup.enter="onKeyupEnter"
                            class="mb-4"
                            outlined
                            :error-messages="this.errors.email"
                        ></v-text-field>
                        <div class="text-subtitle-1 mb-4">Password</div>
                        <v-text-field 
                            hide-details="auto" 
                            label="Password" 
                            v-model="form.password"
                            @keyup.enter="handleLogin"
                            class="mb-4"
                            outlined
                            :type="showPassword ? 'text' : 'password'"
                            :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                            @click:append="showPassword = !showPassword"
                            :rules="passwordRules"
                            validate-on-blur
                            :error-messages="this.errors.password"
                        ></v-text-field>
                        <div class="mt-5 text-body-2 grey--text text--darken-1">
                            <router-link class="mr-2" :to="{name: 'forgot-password'}">Forgot password?</router-link>
                        </div>
                        <authentication-buttons @click="handleLogin" :loading="loading"></authentication-buttons>
                        <div class="mt-5 text-body-2 grey--text text--darken-1">
                            <!-- TODO: add link to contact us page -->
                            Don't have an account? <router-link class="ml-2" :to="{name: 'sign-up'}">Sign up</router-link>
                        </div>
                    </v-form>
                </v-card>
            </v-container>
        </v-col>
        <v-col lg="5" class="d-none d-lg-block sub-col pa-10">
            <div class="ma-auto">
                <img class="mt-10 illustration" style="width: 100%" width="487px" :src="require(`@/assets/svg/illustration.svg`)"/>
            </div>
        </v-col>
    </v-row>
</template>

<script>
import AuthenticationButtons from './AuthenticationButtons.vue';

export default {
    name: "Login",
    components: {
        AuthenticationButtons,
    },
    data() {
        return {
            valid: true,
            loading: false,
            form: {
                email: null,
                password: null,
            },
            errors: {},
            emailRules: [
                value => !!value || 'Required',
                value => /.+@.+\..+/.test(value) || 'E-mail must be valid',
            ],
            showPassword: false,
            passwordRules: [
                value => !!value || 'Required',
            ],
        };
    },
    watch: {
        'form.email': function (){
            if(this.errors && this.errors.email){
                this.errors.email = null
            }
        },
        'form.password': function (){
            if(this.errors && this.errors.password){
                this.errors.password = null
            }
        },
    },
    methods: {
        async handleLogin() {
            const valid = this.$refs.form.validate();

            if(!valid) {
                return;
            }
            
            this.loading = true;
            this.$auth
                .login({ data: this.form, redirect: null })
                .then(({ data }) => {
                    let route =
                        data.data.roles.findIndex((role) => role === "admin") >
                        -1
                            ? this.$auth.options.loginData.redirect.admin
                            : this.$auth.options.loginData.redirect.other;

                    this.$gtag.event('login');
                    this.$router.push(route);
                })
                .catch((error) => {
                    this.loading = false;
                    this.errors = error.response.data.errors;
                });
        },
        onKeyupEnter(e, offset=2) {
            const form = e.target.form;
            const index = Array.prototype.indexOf.call(form, e.target);
            form.elements[index + offset].focus();
            e.preventDefault();
        }
    }
};
</script>

<style lang="scss">
    :root {
        --overflow: hidden;
    }
</style>

<style scoped lang="scss">
    .sub-col {
        background-color: var(--v-primary-lighten5);
        overflow: hidden;
    }
    .main-col{
        position: relative;
        box-shadow: -20px 0px 30px 75px rgba(0, 0, 0, 0.05);
        height: 100vh;
        overflow: scroll;
        -ms-overflow-style: none;  /* Internet Explorer 10+ */
        scrollbar-width: none;  /* Firefox */
    }
    .main-col::-webkit-scrollbar { 
        display: none;  /* Safari and Chrome */
    }
    .illustration{
        margin-left: -100px;
    }
</style>
